<template>
  <v-btn @click="manageAccess()" rounded color="error">
    <v-icon left>mdi-cog</v-icon>
    Gérer les accès utilisateurs
  </v-btn>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      cognitoToken: (state) => state.account.cognito.signInUserSession.accessToken.jwtToken,
    }),
  },
  methods: {
    manageAccess: function() {
      this.$router.push({
        name: "Redirect",
        params: {
          redirect: process.env.VUE_APP_API_ENDPOINT_HI,
          path: "identities",
        },
        query: {
          a: this.cognitoToken,
          r: location.href,
        }
      });
    },
  }
};
</script>
